import { Link } from "gatsby"
import React from "react"
import LeftArrowSvg from "../../../assets/svg/arrow_left.svg"
import LeftSideComponent from '../LeftSideComponent/LeftSideComponent'
import RightSideComponent from "../RightSideComponent/RightSideComponent"
import AuthMessage from "../AuthMessage/AuthMessage"
import MailSvg from '../../../assets/svg/email.svg'
import Form from "./Form"
import * as styles from './forgotpassword.module.scss'

export default _ => {
    const [sent, setSent] = React.useState(false)
  return (
      sent ? 
      <AuthMessage icon={<MailSvg />} title={'Check your email'} text={`We’ve sent you an e-mail. Open the email and confirm password change.`}/> :
    <div className={styles.container}>
      <LeftSideComponent>
        <div className={styles.inner}>
        <Link to="/login" className={styles.back}>
            <LeftArrowSvg />
            Back to Sign In
        </Link>
          <h2>Forgot Password</h2>
          <h4>Enter the email address you used to join Previewed and we’ll send you instructions to reset your password.</h4>
        <Form setSent={setSent}/>
        </div>
      </LeftSideComponent>
      <RightSideComponent />
    </div>
  )
}