import React from "react"
import Firebase from '../../../firebase/index'
import * as Yup from 'yup'
import Form from '../../Common/Form/Form'

export default ({setSent}) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid e-mail address.')
      .required('E-mail is required'),
  });

  async function onSubmit(values){
      let success = await Firebase.resetPassword(values.email)
      if(success){
        setSent(true)
      }
  }

  return (
    <>
    <Form onSubmit={onSubmit} submitButtonText={"Reset"} validationSchema={validationSchema} initialValues={{
          email: "",
        }} fields={[
            {
                placeholder: 'example@mail.com',
                label: "E-mail",
                type: 'email',
                name: 'email'
            },
        ]} />
    </>
  )
}